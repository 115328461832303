



import Component from 'vue-class-component';

import JournalReportNew from '@/components/reports/JournalReportNew.vue';

import { BaseVue } from '../../BaseVue';
import RolledUpJournalReport from './RolledUpJournalReport.vue';

@Component({
  components: {
    RolledUpJournalReport,
    JournalReportNew,
  },
})
export default class RolledUpJournalReportWrapper extends BaseVue {}
